/*
Footer
*/

footer {
  flex-shrink: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: white;
  padding: 24px;

  nav {
    margin-top: 8px;

    svg {
      width: auto;
      height: auto;
      vertical-align: baseline;
    }
  }

  ul {
    list-style: none;
  }

  h6 {
    margin-top: 32px;
    color: $grayDark;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 600;
    font-size: 14px;
  }

  li {
    margin-top: 8px;

    a {
      color: $gray;
      font-size: 14px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .search {
    color: $purple;
    font-size: 18px;

    svg {
      margin-right: 4px;
    }

    path {
      fill: $purple;
    }
  }

  .grid {
    margin-top: 0;
  }

  .author {
    margin-top: 32px;
    color: $grayLight;
    text-align: center;

    small {
      font-size: 13px;
      display: block;
    }

    svg {
      display: inline-block;
      margin-top: 8px;
      width: 96px;
      height: auto;
      opacity: 0.5;
      filter: invert(100%);
    }
  }
}

/*
Breakpoint Tablet <
*/
@media screen and (max-width: calc(calc(#{$lg} - 1px))) {
  footer {
    .search {
      display: block;
      text-align: center;
    }

    ul {
      margin-top: 8px;
    }

    h6 {
      padding-bottom: 4px;
      border-bottom: 1px solid $grayLighter;
    }
  }
}
