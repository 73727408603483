@import '../../webpack_assets/stylesheets/allabrf_variables.scss';

.search-box {
  position: relative;
  width: 100%;

  ::-webkit-input-placeholder {
    color: $gray;
  }

  ::-moz-placeholder {
    color: $gray;
  }

  ::-ms-input-placeholder {
    color: $gray;
  }

  ::-moz-placeholder {
    color: $gray;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    width: 48px;
    height: 60px;
    outline: none;
    border: 0;
    background: transparent;
    text-align: center;
    cursor: pointer;
  }

  .search-button {
    padding-top: 2px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    transition: background-color 0.1s;

    &.active {
      background: $purple;

      path {
        fill: white;
      }
    }
  }

  .search-close {
    path {
      stroke: white;
    }
  }

  path {
    transition: fill 0.1s;
  }

  input {
    padding: 0 48px 0 16px;
    max-width: none;
    width: 100%;
    height: 60px;
    border: 1px solid $grayLight;
    border-radius: 4px;
    color: $grayDarkest;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    transition: border-color 0.1s ease-in;

    &:hover,
    &:focus,
    &:active {
      outline: none;
      border-color: $purple;
    }

    &:valid {
      border-radius: 4px 4px 0 0;
    }
  }

  #autoComplete_list {
    position: absolute;
    z-index: 90;
    margin: 0;
    padding: 0;
    width: 100%;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background: white;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
    color: $gray;
    list-style: none;

    li {
      position: relative;
      padding: 0 16px;
      min-height: 48px;
      line-height: 48px;
      cursor: pointer;

      &:after {
        position: absolute;
        display: block;
        width: calc(100% - 32px);
        height: 1px;
        background: $grayLightest;
        content: '';
      }

      &:last-child:after {
        display: none;
      }

      &:hover {
        background: $grayLightest;
      }

      &.autoComplete_selected {
        background: $grayLightest;
      }

      em {
        color: black;
      }
    }
  }

  .autoComplete_highlighted {
    color: $grayDarkest;
  }

  .selection {
    display: none;
  }

  &[data-placement='landing'] {
    max-width: 520px;
  }

  &[data-placement='navbar'] {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
    display: none;
    width: 100%;
    height: 60px;
    background: darken($purple, 10%);

    &.active {
      display: block;
    }

    ::-webkit-input-placeholder {
      color: white;
      opacity: 0.5;
    }

    ::-moz-placeholder {
      color: white;
      opacity: 0.5;
    }

    ::-ms-input-placeholder {
      color: white;
      opacity: 0.5;
    }

    ::-moz-placeholder {
      color: white;
      opacity: 0.5;
    }

    .search-button {
      display: none;
    }

    button {
      right: 24px;
    }

    input {
      padding: 0 48px;
      border: 0;
      background: transparent;
      color: white;
    }
  }
}

header .searchicon {
  float: right;
  margin-top: 8px;
  margin-left: 20px;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: desaturate(lighten($purple, 10%), 10%);
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background: desaturate(lighten($purple, 20%), 10%);
  }

  svg {
    display: block;
    margin: 12px auto;

    path {
      fill: white;
    }
  }
}
