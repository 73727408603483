/*************/
/**VARIABLES
/*************/

//icons and images
$allabrf_logo: '~allabrf-logo.svg';
$landing_photo: '~placeholder_img.jpg';
$landing_tab1: '~landing_tab1_img.png';
$landing_tab2: '~landing_tab2_img.png';
$landing_tab2_mobile: '~landing_mobile_tab2.png';
$landing_tab3: '~landing_tab3_img.png';
$icon_allabrf_plus: '~icons/plus_filled_white.svg';
$icon_chart: '~icons/chart_icon.svg';
$icon_checkmark: '~icons/checkmark_small_icon.svg';
$icon_close: '~icons/close.svg';
$icon_company_information: '~icons/company_information.svg';
$icon_delete: '~icons/icon-delete.svg';
$icon_document2: '~icons/document2.svg';
$icon_documents: '~icons/gray/documents.svg';
$icon_download: '~download_icon.svg';
$icon_edit: '~icons/gray/edit.svg';
$icon_edit_square: '~icons/edit_icon_square.svg';
$icon_edit_square_purple: '~icons/edit_icon_square_purple.svg';
$icon_financial_management: '~icons/financial_management.svg';
$icon_household: '~icons/household_icon.svg';
$icon_insurance: '~icons/insurance.svg';
$icon_more_info: '~icons/icon_more_info.svg';
$icon_link: '~icons/icon_link.svg';
$icon_member: '~icons/member_icon.svg';
$icon_organizations: '~icons/gray/organizations.svg';
$icon_personal_advice: '~icons/personal_advice.svg';
$icon_piechart: '~icons/piechart.svg';
$icon_piggybank: '~icons/piggybank_icon.svg';
$icon_pin: '~pin.svg';
$icon_pinned_pin: '~pinned_pin.svg';
$icon_premium: '~icons/premium-purple.svg';
$icon_quote: '~quote.jpg';
$icon_settings: '~icons/gray/settings.svg';
$icon_share: '~icons/share_icon.svg';
$icon_sort: '~icons/icon_sort.svg';
$icon_star: '~icons/star.svg';
$icon_star_filled: '~icons/star_filled.svg';
$icon_subscriptions: '~icons/gray/subscriptions.svg';
$icon_trynow60: '~trynow-60.svg';
$icon_trynow: '~trynow.svg';
$icon_uppsagd: '~icons/uppsagd.svg';
$icon_doc: '~icons/invoice.svg';
$icon_garage: '~icons/garage.svg';
$icon_parkering: '~icons/parkering.svg';
$icon_carport: '~icons/carport.svg';
$icon_bank_id: '~icons/bank_id.svg';
$icon_plus: '~icons/plus.svg';
$icon_minus: '~icons/minus.svg';
$icon_select_down_arrow: '~icons/select_down_arrow.svg';
$icon_export_xls: '~icons/export_xls.svg';
$icon_arrow_left: '~icons/arrow-left.svg';
$icon_search_broker: '~icons/search-broker.svg';
$icon_broker_calendar: '~icons/broker_calendar.svg';

//background settings & colors
$bg-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);

//text settings
$init_t_hght: 1;
$norm_t_hght: 1.6;

//fonts
$lato_font: 'Lato', sans-serif;
$catamaran_font: 'Catamaran', sans-serif;

//other variables
$check_transition: background 0.1s ease-in-out;
$table_padding: 15px 30px;
$mtable_padding: 12px 10px;

/*
Colors
*/
$purple: #59277e;
$green: #3cc466;
$yellow: #ffd600;
$red: #dc3545;
$lightBlue: #ebf1f1;
$grayBlue: #cfd9db;
$lightBrown: #d7d3c8;
$grayDarkest: #111;
$grayDarker: #333;
$grayDark: #666;
$gray: #999;
$grayLight: #ccc;
$grayLighter: #eee;
$grayLightest: #f6f6f6;
$whiteOut: #fbfbfb;
$lightPurple: #745493;
$white: #ffffff;

// NOTE: Bootstrap 5 colors.
//   The reason they are here is because the original color palette
//   doesn't have enough shades for decent UI elements.
//   See https://getbootstrap.com/docs/5.2/customize/color/#all-colors.
$red-100: #f8d7da;
$red-200: #f1aeb5;
$red-500: #dc3545;
$green-100: #d1e7dd;
$green-200: #a3cfbb;
$green-500: #198754;
$blue-300: #6ea8fe;
$blue-500: #0d6efd;

/*
Breakpoints
*/
$sm: 480px;
$md: 768px;
$lg: 1024px;
$xl: 1336px;
$commercial-breakpoint: 430px;
$desktop-breakpoint-smaller: 500px;
$desktop-breakpoint: 992px;
$form_width: 620px;
$mobile-width: 680px;
$pad-width: 1025px;
