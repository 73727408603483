body header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 60px;
  background: $purple;

  [class^='icon--'] {
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  a {
    color: white;
  }

  ._wrapper {
    position: relative;
    z-index: 1000;
    display: flow-root;
    min-height: 60px;
  }

  @media screen and (min-width: $lg) {
    .nav--main {
      display: block;
    }
  }

  hgroup {
    display: flex;
    align-items: center;

    a:hover {
      text-decoration: none !important;
    }

    svg {
      margin-right: 16px;
    }
  }

  .logo {
    position: absolute;
    //left: 16px;
    margin-top: 13px;
    width: 120px;
    height: 34px;
    background: url('~allabrf-logo.svg') 50% no-repeat;
    background-size: 100%;
  }

  &.__scroll {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  }

  .menu--secondary li:last-child,
  .menu--tertiary li:last-child {
    margin-left: 0 !important;
  }

  /*hr {
    margin: 16px auto;
    border-color: rgba(255, 255, 255, 0.25);
  }*/

  .avatar {
    margin: -4px 8px 0 0;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background-size: contain;
  }

  .title {
    position: absolute;
    left: 50%;
    display: none;
    color: white;
    text-decoration: none;
    font-weight: 400;
    font-size: 24px;
    line-height: 60px;
    transform: translateX(-50%);

    @media (min-width: $desktop-breakpoint) {
      display: block;
    }
  }
}

body header ~ main {
  margin-top: 60px;
}

/* Mobile specific styles */
@media screen and (max-width: calc(#{$lg} - 1px)) {
  header {
    .avatar {
      display: none;
    }

    a {
      color: white;
    }

    .menu--secondary-p {
      display: none;
    }

    nav:not([role='navigation']) ul.subpages {
      svg {
        margin-right: 15px;
        width: 24px;
        height: 20px;
        vertical-align: middle;

        path {
          fill: white !important;
        }
      }
    }
  }
}

/*  Breakpoints for organization menus - less than desktop */
@media screen and (min-width: $lg) {
  header {
    nav[role='navigation'] {
      &.with_search {
        right: 80px;
      }

      .avatar {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .hide--desktop {
      display: none;
    }
  }

  body.page--organization,
  body.allabrf--walls {
    header {
      height: 120px;

      .adminrow {
        margin-top: 60px;
      }
    }

    header ~ main {
      margin-top: 120px;
    }
  }

  .menu--placeholder {
    overflow: hidden;
    padding: 12px 24px;
    width: 0;
    border-radius: 4px;
    background: desaturate(lighten($purple, 10%), 10%);
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.2s;
    transition: all 1s;
  }

  .menu--placeholder.__active {
    width: 240px;
    opacity: 1;
  }
}

@media screen and (max-width: 1023px) {
  header {
    .nav--main {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 1000;
      display: none;
      overflow-y: scroll;
      padding: 10px 30px 30px 30px;
      width: 100%;
      background: rgba(89, 39, 126, 0.95);
      color: white;
      font-size: 15px;

      &.__active {
        display: block;

        &.first {
          top: 60px;
          border: 6px solid top;
        }

        &.second {
          top: 120px;
          background: transparent;

          ul {
            &.subpages {
              ul {
                margin-left: 30px;
              }
            }
          }
        }
      }
    }

    nav {
      display: block;
    }

    h2 {
      margin: 16px 0 8px 0;
      font-size: 24px;
    }

    h3,
    h4 {
      font-weight: 700;
      font-size: 16px;
    }

    hr {
      margin: 16px auto;
      border-color: rgba(255, 255, 255, 0.25);
    }

    li {
      line-height: 40px;
    }

    hgroup {
      margin: 24px 0 8px 0;

      svg {
        max-height: 36px;
        width: 36px;
        height: auto;
        filter: invert(98%) sepia(0%) saturate(0%) hue-rotate(344deg) brightness(101%) contrast(104%);
      }
    }

    .avatar {
      display: none;
    }

    .title {
      display: none;
    }

    .menu--primary {
      display: flex;
      flex-direction: column;
      padding: 8px 0;

      li {
        line-height: 1.6;

        &.__reverse {
          order: -1;
        }
      }
    }

    .menu--tertiary {
      h4 {
        margin: 24px 0 8px 0;
      }
    }

    .dropdown--toggler {
      display: none;
    }

    .menu--toggler {
      position: absolute;
      top: 6px;
      right: 4px;
      width: 48px;
      height: 48px;
      outline: 0;
      border: 0;
      background: none;
      cursor: pointer;

      &.__active span:nth-child(1) {
        top: 14px;
        left: 16px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &.__active span:nth-child(2) {
        width: 0;
        opacity: 0;
      }

      &.__active span:nth-child(3) {
        top: 31px;
        left: 16px;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      span {
        position: absolute;
        left: 12px;
        display: block;
        width: 24px;
        height: 3px;
        background: white;
        transition: all 0.2s ease-in-out;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;

        &:nth-child(1) {
          top: 15px;
        }

        &:nth-child(2) {
          top: 23px;
        }

        &:nth-child(3) {
          top: 31px;
        }
      }
    }

    .menu--submenu {
      margin-top: 4px;
      border-top: 1px solid rgba(255, 255, 255, 0.25);

      h4 {
        margin: 16px 0 24px 0;
        font-size: 18px;
      }

      [class^='icon--'] {
        width: 36px;
        color: white;
        vertical-align: text-bottom;
        text-align: left;
        font-size: 16px;
        -webkit-filter: none;
        filter: none;
      }

      a {
        display: inline-block;
        height: 40px;
      }

      .icon--forsaljningar {
        font-size: 18px;
      }

      .icon--styrelse {
        font-size: 14px;
      }

      .icon--document,
      .icon--keys,
      .icon--board_room,
      .icon--apartment,
      .icon--piggy {
        font-size: 20px;
      }
    }

    .searchicon {
      margin-right: 48px;
    }
  }
}

@media screen and (min-width: $lg) {
  header {
    h3 {
      letter-spacing: -0.5px;
      font-size: 24px;
      line-height: 1;
    }

    h5 {
      font-size: 16px;
      line-height: 1;
    }

    hgroup {
      margin-bottom: 16px;

      a {
        color: $grayDarkest !important;
      }

      svg {
        max-height: 48px;
        width: 48px;
        height: auto;
      }
    }

    hr {
      margin: 24px auto;
    }

    .logo {
      left: 24px;
    }

    .title {
      position: absolute;
      left: 50%;
      display: block;
      font-weight: 400;
      font-size: 24px;
      line-height: 60px;
      transform: translateX(-50%);
    }

    .dropdown--toggler {
      position: relative;
      margin-right: 8px;
      padding: 0 !important;
      height: 60px;
      border: 0;
      background: none;
      color: white;
      font-size: 16px;
      line-height: 60px;

      &:hover {
        cursor: pointer;
      }

      &::before {
        position: absolute;
        bottom: 0;
        display: block;
        width: calc(100% + 12px);
        height: 2px;
        background: white;
        content: '';
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }

      &::after {
        position: relative;
        top: 25px;
        left: 8px;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-width: 0 1px 1px 0;
        border-style: solid;
        content: '';
        vertical-align: top;
        transition: transform 0.1s ease-in-out;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    .menu--toggler {
      display: none;
    }

    .nav--dropdown {
      position: fixed;
      top: 60px;
      right: 0;
      left: 0;
      z-index: 1001;
      display: none;
      padding: 48px 0;
      width: 100%;
      background: white;
      -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
    }

    .btn--login {
      display: inline-block;
      padding: 0 16px;
      height: 40px;
      border-radius: 2px;
      background: desaturate(lighten($purple, 10%), 10%);
      line-height: 40px;
      transition: background-color 0.1s ease-in-out;

      &:hover {
        background: desaturate(lighten($purple, 20%), 10%);
      }
    }

    .link--profile {
      .avatar {
        margin: -4px 8px 0 0;
      }
    }

    .btn--logout {
      display: inline-block;
      padding: 0 16px;
      height: 40px;
      border: 2px solid desaturate(lighten($purple, 10%), 10%);
      border-radius: 2px;
      line-height: 36px;
      transition: background-color 0.1s ease-in-out;
    }

    .menu--primary {
      float: right;

      li {
        display: inline-block;
        margin-right: 24px;
        line-height: 60px;

        &:last-child {
          margin: 0;
        }

        &:hover {
          .dropdown--toggler {
            &::before {
              opacity: 1;
            }

            &::after {
              top: 29px;
              -webkit-transform: rotate(225deg);
              -ms-transform: rotate(225deg);
              transform: rotate(225deg);
            }
          }
        }

        &:hover > .nav--dropdown {
          display: block;
        }
      }
    }

    .menu--secondary {
      display: flex;

      p {
        max-width: 80%;
        color: $grayDark;
        font-size: 13px;
        line-height: 1.25;
      }

      li {
        margin: 0;
        width: 25%;
        line-height: 1.6 !important;

        a {
          color: $purple;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      ul {
        margin-top: 16px;
        margin-left: 0;
        padding: 0;
      }

      ul li {
        width: 100%;
      }
    }

    .menu--tertiary {
      display: flex;

      li {
        margin: 0;
        width: 50%;
        line-height: 1.6 !important;

        ul {
          float: left;
          width: 50%;
        }

        a {
          color: $purple;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      li li {
        width: 100%;
      }

      h4,
      span {
        display: block;
        height: 32px;
      }

      h4 {
        font-weight: 400;
        font-size: 18px;
      }
    }

    .menu--submenu {
      position: fixed;
      top: 60px;
      left: 0;
      width: 100%;
      height: 60px;
      background: $grayBlue;

      ul {
        display: flex;
        justify-content: center;
      }

      li {
        position: relative;
        margin-right: 24px;
        padding-top: 11px;
        height: 60px;
        text-align: center;

        &:last-child {
          margin: 0;
        }

        &.active::after,
        &:hover::after {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: $grayDarkest;
          content: '';
          opacity: 0.5;
        }
      }

      [class^='icon--'] {
        color: $grayDarkest;
        font-size: 20px;
        -webkit-filter: none;
        filter: none;
      }

      span {
        display: block;
        margin-top: 4px;
      }

      a {
        display: inline-block;
        color: $grayDarkest;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-weight: 700;
        font-size: 11px;
        opacity: 0.75;
      }
    }
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }
}

@media screen and (min-width: 1280px) {
  header {
    .menu--submenu {
      li {
        padding: 0;
        line-height: 60px;
      }

      span {
        display: inline;
      }

      [class^='icon--'] {
        margin-right: 8px;
      }
    }
  }
}

@media screen and (min-width: 1376px) {
  header .menu--submenu li {
    margin-right: 32px;
  }
}

nav,
.nav--dropdown {
  .menu--secondary {
    hgroup {
      svg {
        width: 48px !important;
        height: 48px !important;
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .__hide--desktop {
    display: none;
  }
}

.icon--info_bilder {
  &::before {
    content: $icon-info-and-images;
  }
}

.icon--forsaljningar {
  &::before {
    content: $icon-sales;
  }
}

.icon--styrelse {
  &::before {
    content: $icon-board;
  }
}

.icon--document {
  &::before {
    content: $icon-document;
  }
}

.icon--keys {
  &::before {
    content: $icon-keys;
  }
}

.icon--board_room {
  &::before {
    content: $icon-boardroom;
  }
}

.icon--lagenheter {
  &::before {
    content: $icon-apartment;
  }
}

.icon--omradet {
  &::before {
    content: $icon-area;
  }
}

.icon--piggy {
  &::before {
    content: $icon-piggy-bank;
  }
}

[class^='status--'] {
  display: inline-block;
}

.status--plus,
.status--verified {
  position: relative;
  width: 24px;
  height: 24px;

  &:before {
    position: absolute;
    top: 4px;
    left: 3.5px;
    display: inline-block;
    vertical-align: middle;
    text-transform: none;
    font: normal 14px 'abrf';
  }
}

.status--plus {
  top: 2px;
  margin-left: 3px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;

  &::before {
    content: url($icon_allabrf_plus);
    margin: 5px 0 0 5px;
    zoom: 45%;
  }
}

.status--verified {
  top: 4px;
  right: 3px;

  &::before {
    content: $icon-checkmark-circle;
  }
}

.status__image {
  position: relative;
  cursor: pointer;

  &--verified::after {
    position: absolute;
    top: -8px;
    left: 36px;
    padding: 8px;
    border-radius: 3px;
    background-color: $grayLight;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
    color: $grayDark;
    content: 'Verifierad av styrelsemedlem';
    font-size: 14px;
    line-height: 14px;
    opacity: 0;
  }
}

/*
New ruleset below implemented on Aug 2020. Please note: we will phase out previous styles and breakpoints at a later stage.
*/

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: $purple;

  [class^='icon-'] {
    -webkit-filter: invert(100%);
    filter: invert(100%);
  }

  a {
    color: white;
  }

  hgroup {
    display: flex;
    align-items: center;

    a:hover {
      text-decoration: none !important;
    }

    svg {
      margin-right: 16px;
    }
  }

  .logo {
    position: absolute;
    left: 16px;
    margin-top: 13px;
    width: 120px;
    height: 34px;
    background: url('~allabrf-logo.svg') 50% no-repeat;
    background-size: 100%;
  }

  &._scroll {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  }

  .menu-secondary,
  .menu-tertiary {
    li:last-child {
      margin-left: 0 !important;
    }
  }

  .search-toggler {
    width: 40px;
    height: 40px;
    border: 0;
    border-radius: 50%;
    background: desaturate(lighten($purple, 10%), 10%);
    line-height: 44px;
    transition: background-color 0.1s ease-in-out;

    &:hover {
      background: desaturate(lighten($purple, 20%), 10%);
      cursor: pointer;
    }

    &:before {
      display: inline-block;
      content: $icon-search;
      vertical-align: sub;
      text-transform: none;
      font: normal 18px 'abrf';
      -webkit-filter: invert(100%);
      filter: invert(100%);

      speak: none;
    }
  }

  /*
  Breakpoint Desktop <
  */
  @media screen and (max-width: calc(#{$lg} - 1px)) {
    nav {
      position: fixed;
      top: 60px;
      bottom: 0;
      left: 0;
      overflow-y: scroll;
      padding: 10px 30px 30px 30px;
      width: 100%;
      background: rgba(89, 39, 126, 0.95);
      color: white;
      font-size: 15px;
    }
    h2 {
      margin: 16px 0 8px 0;
      font-size: 24px;
    }
    h3,
    h4 {
      font-weight: 700;
      font-size: 16px;
    }
    p {
      display: none;
    }
    hr {
      margin: 16px auto;
      border-color: rgba(255, 255, 255, 0.25);
    }
    li {
      line-height: 40px;
    }
    hgroup {
      margin: 24px 0 8px 0;

      svg {
        max-height: 36px;
        width: 36px;
        height: auto;
        filter: invert(98%) sepia(0%) saturate(0%) hue-rotate(344deg) brightness(101%) contrast(104%);
      }
    }
    .avatar {
      display: none;
    }
    .title {
      display: none;
    }
    .search-toggler {
      position: absolute;
      top: 10px;
      right: 56px;
    }
    .expandable-menu {
      display: flex;
      flex-direction: column;
      padding: 8px 0;

      li {
        line-height: 1.6;
      }
    }
    .menu-tertiary h4 {
      margin: 24px 0 8px 0;
    }
    .dropdown-toggler {
      display: none;
    }
    .menu-toggler {
      position: absolute;
      top: 6px;
      right: 4px;
      width: 48px;
      height: 48px;
      outline: 0;
      border: 0;
      background: none;
      cursor: pointer;

      span {
        position: absolute;
        left: 12px;
        display: block;
        width: 24px;
        height: 3px;
        background: white;
        transition: all 0.2s ease-in-out;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      span:nth-child(1) {
        top: 15px;
      }

      span:nth-child(2) {
        top: 23px;
      }

      span:nth-child(3) {
        top: 31px;
      }

      &.__active {
        span:nth-child(1) {
          top: 14px;
          left: 16px;
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        span:nth-child(2) {
          width: 0;
          opacity: 0;
        }

        span:nth-child(3) {
          top: 31px;
          left: 16px;
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
    }
    #navbar {
      display: none;
    }
    .menu-submenu {
      margin-top: 4px;
      border-top: 1px solid rgba(255, 255, 255, 0.25);

      h4 {
        margin: 16px 0 24px 0;
        font-size: 18px;
      }

      [class^='icon-'] {
        width: 36px;
        color: white;
        vertical-align: text-bottom;
        text-align: left;
        font-size: 16px;
        -webkit-filter: none;
        filter: none;
      }

      a {
        display: inline-block;
        height: 40px;
      }

      .icon-sales {
        font-size: 18px;
      }

      .icon-board {
        font-size: 14px;
      }

      .icon-document,
      .icon-keys,
      .icon-boardroom,
      .icon-apartment,
      .icon-piggy-bank {
        font-size: 20px;
      }
    }
  }

  /*
  Breakpoint Desktop >
  */
  @media screen and (min-width: $lg) {
    h3 {
      letter-spacing: -0.5px;
      font-size: 24px;
      line-height: 1;
    }
    h5 {
      font-size: 16px;
      line-height: 1;
    }
    hgroup {
      margin-bottom: 16px;

      a {
        color: $grayDarker !important;
      }

      svg {
        max-height: 48px;
        width: 48px;
        height: auto;
      }
    }
    hr {
      margin: 24px auto;
    }
    .search-toggler {
      float: right;
      margin: 10px 0 0 24px;
    }
    .logo {
      left: 24px;
    }
    .title {
      position: absolute;
      left: 50%;
      display: block;
      font-weight: 400;
      font-size: 24px;
      line-height: 60px;
      transform: translateX(-50%);
    }
    .dropdown-toggler {
      position: relative;
      margin-right: 8px;
      height: 60px;
      border: 0;
      background: none;
      color: white;
      font-size: 16px;
      line-height: 60px;

      &:hover {
        cursor: pointer;
      }

      &:before {
        position: absolute;
        bottom: 0;
        display: block;
        width: calc(100% + 12px);
        height: 2px;
        background: white;
        content: '';
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }

      &:after {
        position: relative;
        top: 25px;
        left: 8px;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-width: 0 1px 1px 0;
        border-style: solid;
        content: '';
        vertical-align: top;
        transition: transform 0.1s ease-in-out;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    .menu-toggler {
      display: none;
    }
    .nav-dropdown {
      position: fixed;
      top: 60px;
      right: 0;
      left: 0;
      display: none;
      visibility: hidden;
      padding: 48px 0;
      width: 100%;
      background: white;
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }
    .btn-login {
      display: inline-block;
      padding: 0 16px;
      height: 40px;
      border-radius: 2px;
      background: desaturate(lighten($purple, 10%), 10%);
      line-height: 40px;
      transition: background-color 0.1s ease-in-out;

      &:hover {
        background: desaturate(lighten($purple, 20%), 10%);
      }
    }
    .link-profile {
      .avatar {
        margin: -4px 8px 0 0;
      }
    }
    .btn-logout {
      display: inline-block;
      padding: 0 16px;
      height: 40px;
      border: 2px solid desaturate(lighten($purple, 10%), 10%);
      border-radius: 2px;
      line-height: 36px;
      transition: background-color 0.1s ease-in-out;
    }
    .expandable-menu {
      float: right;

      li {
        display: inline-block;
        margin-right: 24px;
        line-height: 60px;

        &:last-child {
          margin: 0;
        }

        &:hover {
          .dropdown-toggler {
            &:before {
              opacity: 1;
            }

            &:after {
              top: 29px;
              -webkit-transform: rotate(225deg);
              -ms-transform: rotate(225deg);
              transform: rotate(225deg);
            }
          }

          > .nav-dropdown {
            display: block;
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
    .menu-secondary {
      display: flex;

      p {
        max-width: 80%;
        color: $grayDarker;
        font-size: 13px;
        line-height: 1.25;
      }

      li {
        width: 25%;
      }

      ul {
        margin-top: 16px;

        li {
          width: 100%;
        }
      }
    }
    .menu-tertiary {
      display: flex;

      li {
        width: 50%;

        li {
          width: 100%;
        }
      }

      h4,
      span {
        display: block;
        height: 32px;
      }
    }
    .menu-secondary,
    .menu-tertiary {
      li {
        line-height: 1.6 !important;

        a {
          color: $purple;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .menu-submenu {
      position: fixed;
      top: 60px;
      left: 0;
      width: 100%;
      height: 60px;
      background: $grayBlue;

      ul {
        display: flex;
        justify-content: center;
      }

      li {
        position: relative;
        margin-right: 24px;
        padding-top: 11px;
        height: 60px;
        text-align: center;

        &:last-child {
          margin: 0;
        }

        &:hover {
          &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: $grayDark;
            content: '';
          }
        }
      }

      [class^='icon-'] {
        color: $grayDarker;
        font-size: 20px;
        -webkit-filter: none;
        filter: none;
      }

      span {
        display: block;
        margin-top: 4px;
      }

      a {
        display: inline-block;
        color: $grayDarker;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-weight: 700;
        font-size: 11px;
      }
    }
  }

  /*
  High-res tweaks
  */
  @media screen and (min-width: 1280px) {
    .menu-submenu {
      li {
        padding: 0;
        line-height: 60px;
      }

      [class^='icon-'] {
        margin-right: 8px;
      }

      span {
        display: inline;
      }

      a {
        font-size: 12px;
      }
    }
  }
  @media screen and (min-width: 1376px) {
    .menu-submenu li {
      margin-right: 32px;
    }
  }
}

/*
Search Box
*/
.search-box {
  position: relative;
  width: 100%;

  ::-webkit-input-placeholder {
    color: $gray;
  }

  ::-moz-placeholder {
    color: $gray;
  }

  ::-ms-input-placeholder {
    color: $gray;
  }

  ::-moz-placeholder {
    color: $gray;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    width: 48px;
    height: 60px;
    outline: none;
    border: 0;
    background: transparent;
    text-align: center;
    cursor: pointer;
  }

  .search-button {
    padding-top: 2px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    transition: background-color 0.1s;

    &.active {
      background: $purple;

      path {
        fill: white;
      }
    }
  }

  path {
    transition: fill 0.1s;
  }

  input {
    padding: 0 48px 0 16px;
    max-width: none;
    width: 100%;
    height: 60px;
    border: 1px solid $grayLight;
    border-radius: 4px;
    color: $grayDarkest;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    transition: border-color 0.1s ease-in;

    &:hover,
    &:focus,
    &:active {
      outline: none;
      border-color: $purple;
    }

    &:valid {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  #autoComplete_list {
    z-index: 90;
    margin: 0;
    padding: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background: white;
    box-shadow: 0 0 48px rgba(0, 0, 0, 0.1);
    color: $gray;
    list-style: none;

    li {
      position: relative;
      padding: 0 16px;
      line-height: 48px;
      cursor: pointer;

      &:after {
        position: absolute;
        display: block;
        width: calc(100% - 32px);
        height: 1px;
        background: $grayLightest;
        content: '';
      }

      &:last-child:after {
        display: none;
      }

      &:hover {
        background: $grayLightest;
      }
    }
  }

  .autoComplete_highlighted {
    color: $grayDarkest;
  }

  .selection {
    display: none;
  }

  &[data-placement='landing'] {
    max-width: 460px;
  }

  &[data-placement='navbar'] {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
    width: 100%;
    height: 60px;
    background: darken($purple, 10%);

    ::-webkit-input-placeholder {
      color: white;
      opacity: 0.5;
    }

    ::-moz-placeholder {
      color: white;
      opacity: 0.5;
    }

    ::-ms-input-placeholder {
      color: white;
      opacity: 0.5;
    }

    ::-moz-placeholder {
      color: white;
      opacity: 0.5;
    }

    .search-button {
      display: none;
    }

    button {
      right: 12px;
    }

    input {
      padding: 0 24px;
      border: 0;
      background: transparent;
      color: white;
    }

    /*
    Breakpoint Desktop >
    */
    @media screen and (min-width: $lg) {
      input {
        padding: 0 48px;
      }
      button {
        right: 24px;
      }
    }
  }
}
