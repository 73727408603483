nav,
menu {
  svg {
    display: inline-block;
    margin-right: 12px;
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }
}

ul.subpages.subpages--profile {
  svg {
    path {
      fill: white !important;
    }
  }
}


// Setly specific override
#menubar aside {
  height: initial;
}

/* Desktop+ only styles */
aside {
  display: none;
  padding: 24px 16px 16px 16px;
  min-width: 270px;
  width: auto;
  height: 100%;
  border-radius: 4px;
  background: $grayBlue;

  @media screen and (min-width: 1024px) {
    display: block;
  }

  svg path {
    fill: $grayDarker;
  }

  ul {
    list-style: none;
  }

  li {
    position: relative;
    margin-top: 8px;

    &:first-child {
      margin: 0;
    }

    &:hover,
    &.-active {
      a {
        background: rgba(255, 255, 255, 0.25);
      }
    }

    &.-active {
      .subpages {
        display: block;
      }
    }

    a.disabled--tooltip {
      &:hover {
        &::before {
          position: absolute;
          top: 10%;
          left: 50%;
          z-index: 999;
          padding: 0 3px 6px;
          width: 238px;
          border-top: 8px solid $gray;
          border-right: 8px solid rgba(0, 0, 0, 0);
          border-left: 8px solid rgba(0, 0, 0, 0);
          border-radius: 2px;
          background: $gray;
          color: $white;
          content: 'Endast för föreningar med allabrf+';
          text-align: left;
          text-transform: initial;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.4;
          transform: translateX(-50%);
        }
      }
    }

    a.tooltip--financial_management {
      &:hover {
        &::before {
          content: 'Endast för förvaltningskunder';
        }
      }
    }

    a.tooltip--insurance {
      &:hover {
        &::before {
          content: 'Läs mer om våra tjänster';
        }
      }
    }

    a.tooltip--loan {
      &:hover {
        &::before {
          content: 'Läs mer om våra tjänster';
        }
      }
    }

    a.tooltip--reports {
      &:hover {
        &::before {
          content: 'Endast för föreningar med allabrf+ eller förvaltningskunder';
        }
      }
    }

    a.tooltip--mappar {
      &:hover {
        &::before {
          content: 'Endast för föreningar med allabrf+ eller förvaltningskunder';
        }
      }
    }

    a.tooltip--legal_advice {
      &:hover {
        &::before {
          content: 'Endast för föreningar med allabrf+ eller förvaltningskunder';
        }
      }
    }

    a.profile-menu--under-construction {
      &:hover {
        &::before {
          position: absolute;
          top: 10%;
          left: 50%;
          z-index: 999;
          padding: 0 3px 6px;
          width: 238px;
          border-top: 8px solid $gray;
          border-right: 8px solid rgba(0, 0, 0, 0);
          border-left: 8px solid rgba(0, 0, 0, 0);
          border-radius: 2px;
          background: $gray;
          color: $white;
          content: 'Under konstruktion';
          text-align: left;
          text-transform: initial;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.4;
          transform: translateX(-50%);
        }
      }
    }
  }

  a {
    display: block;
    padding: 0 12px;
    height: 44px;
    border-radius: 2px;
    color: $grayDarker;
    line-height: 44px;
  }

  [class^='icon-'] {
    margin-right: 12px;
    font-size: 24px;
  }

  // Tweak
  [class^='icon-subscriptions'] {
    font-size: 18px;
  }

  .subpages {
    display: none;
    margin-top: 2px;
    padding: 2px 0;
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.25);

    li {
      margin: 0;
    }

    a {
      height: 36px;
      background: none !important;
      font-size: 14px;
      line-height: 36px;

      &:hover,
      &.-active {
        font-weight: bold;
      }
    }
  }

  h6 {
    display: inline-block;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
  }

  .shortcuts {
    li {
      display: block;
      margin-bottom: 12px;

      &:hover a {
        background: none;
      }
    }

    a {
      display: inline;
      padding: 0;
      font-size: 14px;
      line-height: 1.5;

      &:hover {
        border-bottom: 1px solid $grayDarker;
        background: none;
      }
    }
  }
}

.expandable-menu {
  .expandable-menu-toggle {
    .expand {
      display: block;
    }

    .collapse {
      display: none;
    }
  }

  .expandable-menu-items {
    display: none;
  }

  &.active {
    .expandable-menu-toggle {
      .expand {
        display: none;
      }

      .collapse {
        display: block;
      }
    }

    .expandable-menu-items {
      display: block;
    }
  }
}
